import test from '../../assets/test.png';

export interface ProjectData {
  name: string;
  nameShort?: string;
  cover: ImageData;
  description: string;
  images?: Array<ImageData>;
  url?: string;
}

export interface ImageData {
  src: string;
  alt?: string;
}

export interface ProjectGroupData {
  title: string;
  projects: Array<ProjectData>;
  colour?: string;
}

export const aiProjects: Array<ProjectGroupData> = [
  {
    title: "Text Processing",
    projects: [
      {
        name: "Text Inference",
        cover: { src: test, alt: "" },
        description: ""
      },
      {
        name: "Chat Inference",
        cover: { src: test, alt: "" },
        description: ""
      },
      {
        name: "Code Assistance",
        cover: { src: "", alt: "" },
        description: ""
      }
    ]
  },
  {
    title: "Images Processing",
    projects: [
      {
        name: "Images Generation",
        cover: { src: "", alt: "" },
        description: ""
      },
      {
        name: "Images Upscaling",
        cover: { src: "", alt: "" },
        description: ""
      },
      {
        name: "Background Removal",
        cover: { src: "", alt: "" },
        description: ""
      }             
    ]
  },
  {
    title: "Audio Processing",
    projects: [
      {
        name: "Music Generation",
        cover: { src: "", alt: "" },
        description: ""
      },
      {
        name: "Audio Transcription",
        cover: { src: "", alt: "" },
        description: ""
      },
      {
        name: "Voice Generation",
        cover: { src: "", alt: "" },
        description: ""
      }
    ]
  }
];

export const hostingProjects: Array<ProjectGroupData> = [
  {
    title: "Hosting",
    projects: [
      {
        name: "Websites Hosting",
        cover: { src: test, alt: "" },
        description: ""
      },
      {
        name: "Applications Hosting",
        cover: { src: test, alt: "" },
        description: ""
      },
      {
        name: "Websites Development",
        cover: { src: "", alt: "" },
        description: ""
      }
    ]
  },
  {
    title: "Virtualisation",
    projects: [
      {
        name: "Virtual Machines",
        cover: { src: "", alt: "" },
        description: ""
      },
      {
        name: "VPC",
        cover: { src: "", alt: "" },
        description: ""
      },
      {
        name: "Docker Containers",
        cover: { src: "", alt: "" },
        description: ""
      }             
    ]
  },
  {
    title: "Cloud",
    projects: [
      {
        name: "S3 Storage",
        cover: { src: "", alt: "" },
        description: ""
      },
      {
        name: "Block Storage",
        cover: { src: "", alt: "" },
        description: ""
      },
      {
        name: "Version Control",
        cover: { src: "", alt: "" },
        description: ""
      }
    ]
  }
];
