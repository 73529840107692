import React from 'react';
import styled from 'styled-components';
import { ImageData } from './ProjectData';

interface ProjectCardImageProps {
  iData: ImageData;
}

export const ProjectCardImage: React.FC<ProjectCardImageProps> = (props) => {
  return (
    <ProjectCardImageStyled src={props.iData.src} alt={props.iData.alt}/>
  );
};

const ProjectCardImageStyled = styled.img`
  width: 100%;
  height: 100%;
`;

export default ProjectCardImage;
