import React from 'react';
import './App.css';
import Main from './pages/Main';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import AI from './pages/Cloud';
import Manufacturing from './pages/Manufacturing';
import Services from './pages/Services';
import Hosting from './pages/Hosting';

const App: React.FC = () => {
  const routes = [
    {
      path: "/",
      element: <Main />,
    },  
    {
      path: "/ai",
      element: <AI />,
    },
    {
      path: "/hosting",
      element: <Hosting />,
    },
    {
      path: "/manufacturing",
      element: <Manufacturing />,
    }, 
    {
      path: "/services",
      element: <Services />,
    }, 
  ];

  return (
      <div className="App">
        <RouterProvider router={createBrowserRouter(routes)} />
      </div>  
  );
}

export default App;
