export interface TechnologyData {
  name: string;
  image: string;
  imageAlt?: string;
  url: string;
  description: string;
}

export interface TechnologyGroupData {
  title: string;
  technologies: Array<TechnologyData>;
}

export const technologies: Array<TechnologyGroupData> = [
  {
    title: "Hosting",
    technologies: [
      {
        name: "Websites Hosting",
        image: "https://cdn.iconscout.com/icon/free/png-512/react-1-282599.png",
        url: "",
        description: ""
      },
      {
        name: "Websites Development",
        image: "https://static-00.iconduck.com/assets.00/next-js-icon-512x512-zuauazrk.png",
        url: "",
        description: ""
      },
      {
        name: "Applications Hosting",
        image: "https://www.teamwaresolutions.net/wp-content/uploads/2019/10/ed259587041c5ab79f24cb1e341f0a37.png",
        url: "",
        description: ""
      }         
    ]
  },
  {
    title: "AI & Cloud",
    technologies: [
      {
        name: "VPC & Virtual Machines",
        image: "https://www.teamwaresolutions.net/wp-content/uploads/2019/10/ed259587041c5ab79f24cb1e341f0a37.png",
        url: "",
        description: ""
      },
      {
        name: "AI Applications",
        image: "https://pythonforundergradengineers.com/posts/zappa/images/flask_icon.png",
        url: "",
        description: ""
      },
      {
        name: "AI Inference",
        image: "https://bykowski.pl/wp-content/uploads/2013/10/aspnet.png",
        url: "",
        description: ""
      }
    ]
  },  
  {
    title: "Manufacturing",
    technologies: [
      {
        name: "CAD Design",
        image: "https://cdn4.iconfinder.com/data/icons/various-icons-2/476/Unity.png",
        url: "",
        description: ""
      },
      {
        name: "3D Printing",
        image: "https://phaser.io/images/img.png",
        url: "",
        description: ""
      },
      {
        name: "Custom Designs",
        image: "https://phaser.io/images/img.png",
        url: "",
        description: ""
      }
    ]
  },
  {
    title: "Services",
    technologies: [
      {
        name: "IT Administration",
        image: "https://cdn4.iconfinder.com/data/icons/various-icons-2/476/Unity.png",
        url: "",
        description: ""
      },
      {
        name: "DevOps",
        image: "https://phaser.io/images/img.png",
        url: "",
        description: ""
      },
      {
        name: "Cloud Consulting",
        image: "https://phaser.io/images/img.png",
        url: "",
        description: ""
      }
    ]
  }
];
