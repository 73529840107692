import React from 'react';
import Layout from '../components/layout/Layout';
import Carousel from '../components/carousel/Carousel';

const Services: React.FC = () => {
  return(
    <Layout>
      <Carousel/>    
    </Layout>
  );
}
export default Services;