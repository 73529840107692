import React from 'react';
import styled from 'styled-components';
import ProjectCard from './ProjectCard';
import { ProjectGroupData } from './ProjectData';

const ProjectCardGroup: React.FC<ProjectCardGroupProps> = (props) => {
  return (
    <ProjectCardGroupStyled>
      <h3>{props.pgData.title}</h3>
      <ProjectCardGroupRow>
        {props.pgData.projects.map((t, index) => {
          return (<ProjectCard key={index} pData={t} />);
        })}
      </ProjectCardGroupRow>
        
    </ProjectCardGroupStyled>
  );
};
interface ProjectCardGroupProps {
  pgData: ProjectGroupData;
}


const ProjectCardGroupRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

const ProjectCardGroupStyled = styled.div`
  width: 80%;
`;

export default ProjectCardGroup;