import React from 'react';
import styled from 'styled-components';
import { SectionHeader } from '../common/TextHeaders';
import Column from './Column';

const Columns: React.FC<ColumnsProps> = (props) => {
  return(
    <ColumnsContainer>
      <SectionHeader>{props.header}</SectionHeader>
      <FlexContainer>
        <Column header='Test' content='Test'/>
        <Column header='Test' content='Test'/>
        <Column header='Test' content='Test'/>
      </FlexContainer>
    </ColumnsContainer>
  );
}
export default Columns;

export interface ColumnsProps {
  header: string;
}

export const FlexContainer = styled.div`
  width: 100%;
  height: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

const ColumnsContainer = styled.div`
  max-width: 1200px;
  width: 100vw;
  height: 40vh;
  background-color: lightgrey;
`;

