import React from 'react';
import styled from 'styled-components';
import { WebsiteHeader } from '../common/TextHeaders';
import Slider from "react-slick";

import img_01 from '../../assets/home-carousel/01.jpg';
import img_02 from '../../assets/home-carousel/02.jpg';
import img_03 from '../../assets/home-carousel/03.jpg';
import img_04 from '../../assets/home-carousel/04.jpg';
import img_05 from '../../assets/home-carousel/05.jpg';

const Header: React.FC = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return(
    <HeaderContainer>
      <WebsiteHeader> Andy's Tech Lab </WebsiteHeader>
        <Slider {...settings}>
          <HeaderSlide><HeaderImage src={img_01} alt=''/><HeaderSlideText>Application Development</HeaderSlideText></HeaderSlide>
          <HeaderSlide><HeaderImage src={img_02} alt=''/><HeaderSlideText>DevOps & IT support</HeaderSlideText></HeaderSlide>
          <HeaderSlide><HeaderImage src={img_03} alt=''/><HeaderSlideText>3D printing and CAD Design</HeaderSlideText></HeaderSlide>
          <HeaderSlide><HeaderImage src={img_04} alt=''/><HeaderSlideText>AI applications for business</HeaderSlideText></HeaderSlide>
          <HeaderSlide><HeaderImage src={img_05} alt=''/><HeaderSlideText>Cloud services and hosting</HeaderSlideText></HeaderSlide>
        </Slider>
                 
    </HeaderContainer>
  );
}

const HeaderSlideText = styled.h1`
  width: 50%;
  height: 5vh;
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  background: rgba(128, 128, 128, 0.66);
  margin: 0;
`;

const HeaderSlide = styled.div`
  width: 100%;
  max-width: 1200px;
  height: 45vh;
  max-height: 420px;
  position: relative;
  bottom: 0;
`;

const HeaderImage = styled.img`
  width: 100%;
  max-width: 1200px;
  height: 45vh;
  max-height: 420px;
`;

const HeaderContainer = styled.header`

  max-width: 1200px;
  width: 100vw;
  margin-top: 5vh;
  height: 55vh;
  background-color: grey;


`;




export default Header;