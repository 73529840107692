import React from 'react';
import styled from 'styled-components';
import { ProjectGroupData } from './ProjectData';
import ProjectCardGroup from './ProjectCardGroup';
import { SectionHeader } from '../common/TextHeaders';

const Projects: React.FC<ProjectProps> = (props) => {

  return(
    <ProjectsContainer id={"projects"}>
      <SectionHeader>{props.header}</SectionHeader>
      <GridContainer>
        {
          props.projects.map((pg,index) => {
            return(<ProjectCardGroup key={index} pgData={pg}/>)
          })
        }
      </GridContainer>        
    </ProjectsContainer>
  );
}
export default Projects;

interface ProjectProps {
  header: string;
  projects: ProjectGroupData[];
}

export const GridContainer = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const ProjectsContainer = styled.div`
  max-width: 1200px;
  width: 100vw;
  margin-top: 5vh;
  min-height: 95vh;
  background-color: purple;
`;
