import React from 'react';
import styled from 'styled-components';
import { SectionHeader } from '../common/TextHeaders';

const Contact: React.FC = () => {
  return(
    <ContactContainer id={"contact"}>
      <SectionHeader>Get in touch</SectionHeader>
      <ContactForm>
        <FieldGroup>
          <ContactField>
          <p>Your name:</p><ContactInput type="text"></ContactInput>
          </ContactField>

          <ContactField>
          <p>Your email:</p><ContactInput type="email"></ContactInput>
          </ContactField>
        </FieldGroup>        

        <div>
          <p>Your message:</p><MessageInput></MessageInput>
        </div>

        <SubmitGroup>
          <div>
            <p>Agreement:</p><CheckBoxInput type="checkbox"></CheckBoxInput>
          </div>
          
          <button type="submit">Submit</button>
        </SubmitGroup>        
      </ContactForm>
    </ContactContainer>
  );
}
export default Contact;

const FieldGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const SubmitGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const ContactField = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: white;
`;

const ContactForm = styled.form`
  height: 80%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border: 0;
`;

const ContactInput = styled.input`
  height: 3vh;
  border: 0;
  background-color: lightblue;
`;

const MessageInput = styled.textarea`
  height: 10vh;
  width: 100%;
  border: 0;
  background-color: lightblue;
  resize: none;
`;

const CheckBoxInput = styled.input`

`;

const ContactContainer = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  max-width: 1200px;
  width: 100vw;
  margin-top: 5vh;
  height: 55vh;
  background-color: lightgrey;
`;