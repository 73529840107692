import React from 'react';
import styled from 'styled-components';
import Contact from '../contact/Contact';
import Footer from '../footer/Footer';
import Navigation from '../navigation/Navigation';

const Layout: React.FC<{ children: React.ReactNode }> = ({children}) => {
  return(
  <>
    <Navigation/>
    <LayoutWrapper>  
   
      {children}
      <Contact/>
      <Footer/>
    </LayoutWrapper>
  </>    
  );
}
export default Layout;

const LayoutWrapper = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;