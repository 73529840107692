import React from 'react';
import Projects from '../components/projects/Projects';
import Layout from '../components/layout/Layout';
import { aiProjects } from '../components/projects/ProjectData';

const AI: React.FC = () => {
  return(
    <Layout>
      <Projects header="AI Services" projects={aiProjects}/>      
    </Layout>
  );
}
export default AI;