import styled from "styled-components";

export const WebsiteHeader = styled.h1`
  color: white;
  margin-top: 0;
  margin-bottom: 0;
  height: 10vh;
  font-size: 6vh;  

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SectionHeader = styled.h2`
  color: white;
`;