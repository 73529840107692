import React from 'react';
import styled from 'styled-components';
import { SectionHeader } from '../common/TextHeaders';

const Footer: React.FC = () => {
  return(
    <FooterContainer>
      <FlexContainer>
        <ContactInfo>
          <SectionHeader>Contact Us</SectionHeader>
          <p>ul. Gallusa 12/120, 40-594 Katowice</p>
          <p>698 981 829</p>
          <p>contact@andystechlab.com</p>

        </ContactInfo>
        <ContactInfo>
          <SectionHeader>Find us on:</SectionHeader>
          <p>Facebook</p>
          <p>LinkedIn</p>
          <p>Instagram</p>
        </ContactInfo>
      </FlexContainer>
    </FooterContainer>
  );
}
export default Footer;

const ContactInfo = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const FlexContainer = styled.div`
  width: 80%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const FooterContainer = styled.footer`
  max-width: 1200px;
  width: 100vw;
  margin-top: 5vh;
  height: 35vh;
  background-color: grey;
`;