import React from 'react';

import Technologies from '../components/technologies/Technologies';
import Layout from '../components/layout/Layout';
import Header from '../components/header/Header';
import Columns from '../components/columns/Columns';

const Main: React.FC = () => {
  return(
    <Layout>   
      <Header/>  
      <Columns header='About Us'/>
      <Technologies/>      
    </Layout>
  );
}
export default Main;
