import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

const Carousel: React.FC<CarouselProps> = (props) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  
  return(
    <CarouselContainer id={"projects"}>      
      <Slider {...settings}>
        <CarouselImage src='https://wallpapercave.com/wp/wp5338279.jpg' alt=''/>
        <CarouselImage src='https://wallpapercave.com/wp/wp5338279.jpg' alt=''/>
        <CarouselImage src='https://wallpapercave.com/wp/wp5338279.jpg' alt=''/>
        <CarouselImage src='https://wallpapercave.com/wp/wp5338279.jpg' alt=''/>
        <CarouselImage src='https://wallpapercave.com/wp/wp5338279.jpg' alt=''/>
        <CarouselImage src='https://wallpapercave.com/wp/wp5338279.jpg' alt=''/>
      </Slider>
    </CarouselContainer>
  );
}
export default Carousel;

interface CarouselProps {
  images?: string[];
}

const CarouselImage = styled.img`
  height: 45vh;
`;

const CarouselContainer = styled.div`
  max-width: 1200px;
  width: 100vw;
  margin-top: 5vh;
  height: 45vh;
  background-color: cyan;
`;