import React from 'react';
import styled from 'styled-components';
import ProjectCardImage from './ProjectCardImage';
import { ProjectData } from './ProjectData';

const ProjectCard: React.FC<ProjectCardProps> = (props) => {
  return (
    <ProjectCardStyled>
      <ProjectCardImageWrapper>
        <ProjectCardImage iData={props.pData.cover}/>
      </ProjectCardImageWrapper>            
      <ProjectHeader>{props.pData.nameShort ?? props.pData.name}</ProjectHeader>
    </ProjectCardStyled>
  );
};

interface ProjectCardProps {
  pData: ProjectData;
}

const ProjectHeader = styled.h4`
  margin: 0;
  width: 100%;
  height: 2vw;
`;


const ProjectCardImageWrapper = styled.div`
  width: 10vw;
  height: 10vw;
`;

const ProjectCardStyled = styled.div`
  width: 16vw;
  height: 12vw;
  background-color: antiquewhite;
  border-radius: 10px;
`;




export default ProjectCard;


