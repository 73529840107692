import React from 'react';
import Projects from '../components/projects/Projects';
import Layout from '../components/layout/Layout';
import { hostingProjects } from '../components/projects/ProjectData';

const Hosting: React.FC = () => {
  return(
    <Layout>
      <Projects header="Hosting & Cloud" projects={hostingProjects}/>      
    </Layout>
  );
}
export default Hosting;