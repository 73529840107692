import React from 'react';
import styled from 'styled-components';
import { TechnologyData } from './TechnologyData';

const Technology: React.FC<TechnologyProps> = (props) => {
  return (
    <TechnologyStyled>
      <TechnologyIconStyled
        alt={props.tData.imageAlt}
        src={props.tData.image}
      />
      <TechnologyHeader>{props.tData.name}</TechnologyHeader>
    </TechnologyStyled>
  );
};

interface TechnologyProps {
  tData: TechnologyData;
}

const TechnologyIconStyled = styled.img`
  width: 7vh;
  height: 7vh;
`;

const TechnologyStyled = styled.div`
  background-color: lightgray;
  display: flex;    
  justify-content: space-between;
  padding-left: 1vh;
  padding-right: 1vh;
  height: 7vh;
`;

const TechnologyHeader = styled.h4`
  height:100%;
  margin: 0;
  display: flex; 
  align-items: center;
`;

export default Technology;
