import React from 'react';
import styled from 'styled-components';
import { TechnologyGroupData } from './TechnologyData';
import Technology from "./Technology";

const TechnologyGroup: React.FC<TechnologyGroupProps> = (props) => {
  return (
    <TechnologyGroupStyled>
      <TechnologyGroupHeader>{props.tgData.title}</TechnologyGroupHeader>
      <TechnologyGroupElementsStyled>
        {props.tgData.technologies.map((t, index) => {
          return (<Technology key={index} tData={t} />);
        })}
      </TechnologyGroupElementsStyled>        
    </TechnologyGroupStyled>
  );
};

interface TechnologyGroupProps {
  tgData: TechnologyGroupData;
}

const TechnologyGroupElementsStyled = styled.div`
  height: 80%;
  display: flex;    
  justify-content: space-evenly;
  flex-direction: column;
  //background-color: red;
`;

const TechnologyGroupHeader = styled.h3`
  height:20%;
  margin: 0;
  display: flex; 
  align-items: center;
  justify-content: center;
`;

const TechnologyGroupStyled = styled.div`
  background-color: white;
  width: 24%;
  border-radius: 10px;
`;

export default TechnologyGroup;
