import React from 'react';
import styled from 'styled-components';
import { SectionHeader } from '../common/TextHeaders';
import { technologies } from './TechnologyData';
import TechnologyGroup from './TechnologyGroup';

const Technologies: React.FC = () => {
  const tech = technologies;

  return(
    <TechnologiesContainer id={"projects"}>
      <SectionHeader>Our offer</SectionHeader>
      <FlexContainer>
        {
          tech.map((tg,index) => {
            return(<TechnologyGroup key={index} tgData={tg}/>)
          })
        }
      </FlexContainer>            
    </TechnologiesContainer>
  );
}
export default Technologies;

const TechnologiesContainer = styled.div`
  max-width: 1200px;
  width: 100vw;
  margin-top: 5vh;
  height: 45vh;
  background-color: grey;
  //background-color: olive;  
`;

const FlexContainer = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: space-around;
`;