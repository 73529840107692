import React from 'react';
import styled from 'styled-components';

const Column: React.FC<ColumnProps> = (props) => {
  return(
    <ColumnContainer>
      <h3>{props.header}</h3>
      <p>{props.content}</p>
    </ColumnContainer>
  );
}
export default Column;

export interface ColumnProps {
  header: string;
  content: string;
}

export const ColumnContainer = styled.div`
  width: 24%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
`
