import React from 'react';
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";
import { FaHome, FaServer, FaCloud, FaMailBulk } from 'react-icons/fa';
import { FaScrewdriverWrench } from 'react-icons/fa6';
import { MdFactory } from 'react-icons/md';

const Navigation: React.FC = () => {
  const navigate = useNavigate();
  return(
    <NavigationWrapper>
      <NavigationContainer>
        <NavigationButton onClick={() => navigate("/")}><FaHome/> Home</NavigationButton>
        <NavigationButton onClick={() => navigate("/hosting")}><FaServer/> Hosting</NavigationButton>
        <NavigationButton onClick={() => navigate("/ai")}><FaCloud/> AI & Cloud</NavigationButton>        
        <NavigationButton onClick={() => navigate("/manufacturing")}><MdFactory/> Manufacturing</NavigationButton>
        <NavigationButton onClick={() => navigate("/services")}><FaScrewdriverWrench/> Services</NavigationButton>
        <NavigationButton onClick={() => navigate("/contact")}><FaMailBulk/> Contact</NavigationButton>
      </NavigationContainer>
    </NavigationWrapper>        
  );
}
export default Navigation;

export const NavigationLogo = styled.div`
  width: 8vw;
  height: 100%;
  background-color: cyan;
  
`;

const NavigationButton = styled.button`
  width: 9vw;
  height: 80%;
  color: white;
  font-size: 2vh; 
  background: rgba(0,0,0,0);
  border: 0;
  border-radius: 25px;
  &:hover {
    background: rgba(0, 0, 0.5, 0.5);
    }
`;

const NavigationWrapper = styled.div`
  width: 100%;
  height: 5vh; 
 
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
`;

const NavigationContainer = styled.nav`
  max-width: 1200px;
  width: 100%;
  height: 100%;
  margin: 0;  
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: blue;
`;